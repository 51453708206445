import './_App.scss';
import './assets/styles/_base.scss';

import {BrowserRouter, Routes, Route} from 'react-router-dom';



import {Helmet} from 'react-helmet';
import _ from 'lodash';
//@ts-ignore
import smoothscroll from 'smoothscroll-polyfill';

import React from 'react';
import Home from './components/Home/Home';
import Skills from './components/Skills/Skills';
import Experience from './components/Experience/Experience';
import Contact from './components/Contact/Contact';
import Nav, {NavRouteId} from './components/Nav/Nav';
import Tools from './tools/Tools';
import Radio from './tools/Radio';
import Screen from './tools/Screen';
import ErrorScreen from './components/ErrorScreen/ErrorScreen'
import Modals from './components/Modals/Modals';
import Preloader from './components/Preloader/Preloader';
import Footer from './components/Footer/Footer';



interface Props{}
interface State {
    routeId:NavRouteId|null,
    screenWidth:number,
    modalId:string,
    isReady:boolean,
}

let instance:App;

export default class App extends React.Component<Props, State> {

    static radio:Radio;

    htmlEl:any;
    isScrollingToScreen:boolean = false;

    handleWindowResizeDebounceFunc:any;

    static setState(obj:any, callback?:()=> {}){
        if(!instance) return;
        instance.setState(obj, callback);
    }

    constructor(props:Props){
        super(props);
        // console.log("App()")
        // console.log(" - props = ", props)
        
        this.state = {
            routeId:null,
            screenWidth: Screen.screenWidth,
            modalId:"",
            isReady:false,

        }

        // Handle window resize
        this.handleWindowResizeDebounceFunc = _.debounce(this.handleWindowResize, 500);
        window.onresize = this.handleWindowResizeDebounceFunc;

        instance = this;

        App.radio = new Radio()

        smoothscroll.polyfill();
    }

    componentDidMount(): void {
        // console.log("App.componentDidMount()")
        // document.addEventListener('DOMContentLoaded', this.init, false);
        this.init();
        
    }

    
    componentDidUpdate(prevProps:Props, prevState:State){
        // console.log("App.componentDidUpdate()");

        // Going FROM error screen TO valid non-error route
        if(prevState.routeId === NavRouteId.error && this.state.routeId !== NavRouteId.error){
            // console.log(" - routed away from error")
            
            // Scroll to page title screen
            Tools.scrollIntoView(this.state.routeId);
        }

        
    }

    async init(){

        instance.htmlEl = document.querySelector('html');

        if(Tools.isTouchDevice){
            Tools.addClassToHTML('device-touch')
            
        }else{
            Tools.addClassToHTML('device-hover')
        }
        
        // Set initial route
        const pathname:string = window.location.pathname.substring(1);
        let routeId:NavRouteId = NavRouteId.home;
        if(Nav.isValidRouteId(pathname)) routeId = pathname as NavRouteId;
        
        // Unreveals preloader
        await this.setIsReady(0.1);

        // Listen for user manually scrolling the window
        window.addEventListener('wheel', this.handleWindowWheel);
        
        // Route to first screen (depends on the url fragments e.g. '/skills')
        await Tools.later(0.1);
        App.routeToScreen(routeId, true, false);

        
    }

   
   
    /**
     * Sets state isReady value to true, which reveals the site
     * @param delaySecs 
     */
    setIsReady = async (delaySecs:number = 0) => {
        if(delaySecs) await Tools.later(delaySecs);
        this.setState({isReady:true});
    }
   
    static get isScrollingToScreen():boolean{
        return instance.isScrollingToScreen;
    }
    
    get screenWidth():number{
        return window.innerWidth
    }
    /**
     * Set the window location href withou reloading the app.
     * @param routeId - One of the navRouteId values
     * @returns 
     */
    static setLocation(routeId:NavRouteId):void{
        // console.log(`App.setLocation(${routeId}) ${Date.now().toString()}`)
        if(routeId === instance.state.routeId){
            return;
        }
        window.history.replaceState(null, null, `/${routeId}`)
    }


    static showModal(modalId:string){
        App.setState({modalId: modalId});
    }

    /**
     * 
     * @param routeId - One of the navRouteId values
     * @param scrollToScreen - scroll the page to the selected screen-anchor component
     * @param smooth - scroll smoothly. Will jump instantly if set to false.
     * @returns 
     */
    static routeToScreen = async (routeId:NavRouteId, scrollToScreen:boolean = true, smooth:boolean = true) => {
        
        
        // Early return
        // if(routeId === instance.state.routeId) return;
        if(instance.isScrollingToScreen) return;

        // console.log(`App.routeToScreen(${routeId})`)

        App.radio.emit(Radio.NAV_CHANGE, {routeId: routeId})

        // Add classes
        if(instance.htmlEl){
            instance.htmlEl.classList.remove("screen-" + instance.state.routeId);
            instance.htmlEl.classList.add("screen-" + routeId);
        }
        
        // Change href
        this.setLocation(routeId);

        // Set the correct nav route id
        App.setState({routeId:routeId})
        
        // Scroll to screen
        if(scrollToScreen){
            instance.isScrollingToScreen = true;

            // await Tools.later(1.5);

            // Scroll to page title screen
            // var el = document.getElementById(routeId);
            Tools.scrollIntoView(routeId, smooth);

            await Tools.later(1);
            instance.isScrollingToScreen = false;
        }

    }



    // All he routs in one handy place
    routes:any[] = [
        {routeId: NavRouteId.home},
        {routeId: NavRouteId.skills},
        {routeId: NavRouteId.experience},
        {routeId: NavRouteId.contact}
    ];

    /**
     * Which route is the user on?
     * @returns NavRouteId
     */
     getRouteIdFromScrollPos = ():NavRouteId|null => {
        // console.log("App.getRouteIdFromScrollPos()")
        
        let currentRoute:any = null;
        // Get y pos for each route
        for(let route of this.routes){
            const routeEl = document.getElementById(route.routeId);
            const rect = routeEl.getBoundingClientRect();
            route.y = rect.y;
            if(route.y <= 0 ) currentRoute = route;
        }

        // console.log(" - currentRoute.routeId = " + currentRoute.routeId)
        if(currentRoute) return currentRoute.routeId;
        return null;
    }

    /**
     * If a {NavRouteId}-section-pages element is in the viewport, then return which one it is
     * @returns NavRouteId | null
     */
    getSectionPagesRouteIdFromScrollPos = ():NavRouteId|null => {
        // console.log("App.getSectionPagesRouteIdFromScrollPos()")
        let routeId:NavRouteId|null = null
        for(let route of this.routes){
            // const routeEl = document.getElementById(route.routeId);
            const pagesEl = document.getElementById(route.routeId + '-pages');
            if(pagesEl){
                const rect = pagesEl.getBoundingClientRect();
                if(rect.y <= 0 && rect.bottom > 0){
                    routeId = route.routeId;
                    break;
                }

            }
        }
        return routeId;
    }

    /**
     * Replace any existing {NavRouteId}-section-pages class with the given one
     * @param routeId 
     */
    replaceSectionPagesClass(routeId:NavRouteId){
        // Remove existing section-pages class
        for(let route of this.routes){
            Tools.removeClassFromHTML('section-' + route.routeId + "-pages")    
        }

        // Add new section-pages class
        Tools.addClassToHTML('section-' + routeId + "-pages");
    }

    






    // -----------------------------------------------------------
    // HANDLERS

    
    handleWindowResize = () => {
        // console.log("Experience.handleWindowResize");
        this.setState({
            screenWidth: this.screenWidth
        })
    }

    handleModalClose = () => {
        this.setState({modalId:""})
    }

    handleWindowWheel = (e:any) => {
        // console.log("App.handleWindowWheel()")
        this.detectScrollingInterrupted();
       
    }


    detectScrollingInterrupted(){
         // Oh dear, the user has interrupted the default scrolling. How annoying.
         if(this.isScrollingToScreen){
            this.isScrollingToScreen = false;
            
            // Sync nav and url with current screen
            let routeId:NavRouteId|null = this.getRouteIdFromScrollPos();
            if(routeId && this.state.routeId !== routeId){
                App.routeToScreen(routeId, false);                
            }

            // Set the right {NavRouteId}-section-pages class
            routeId = this.getSectionPagesRouteIdFromScrollPos();
            if(routeId) this.replaceSectionPagesClass(routeId)
        }   
    }
   

    
    
    render(){
        return (
            
            <BrowserRouter>
                <div className="app">
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <link rel="canonical" href="https://darriux.com" />
                        <title>Darri Donnelly: Front-end Dev &amp; UX Designer</title>
                        <meta name="description" content="Front-end developer and UX designer based in Edinburgh, UK"/>
                    </Helmet>
                    
                   
                    
                    <Routes>
                        <Route 
                            
                                path="/error"
                                element={(
                                        <div className="app-content">
                                            <Nav 
                                                routeId={this.state.routeId}
                                                screenWidth={this.state.screenWidth}
                                            />
                                        

                                            <ErrorScreen/>
                                        </div>
                                    )
                                }
                            />
                        
                        
                        <Route 
                                path="*"
                                element={(
                                        <div className="app-content">

                                            <Nav 
                                                routeId={this.state.routeId}
                                                screenWidth={this.state.screenWidth}
                                            />
                                            <Home />
                
                                            <Skills />

                                            <Experience 
                                                screenWidth={this.state.screenWidth}
                                            />

                                            <Contact />

                                            <Footer/>
                                        </div>
                                    )
                                }
                            />

                           
                    </Routes>
                    
                    
                    <Preloader
                        isReady={this.state.isReady}
                    />
                
                        
                    <Modals
                        modalId={this.state.modalId}
                        onClose={this.handleModalClose}
                    />
                    
                    

                    
                </div>
            </BrowserRouter>
            
        );
    }
}



import React from 'react';
import './_styles.scss';


import Intersector, {InitPosition, InitView, RatioDirection} from '../Intersector/Intersector';
import Tools from '../../tools/Tools';
import { NavRouteId } from '../Nav/Nav';
import ScreenAnchor from '../ScreenAnchor/ScreenAnchor';
import PagesTitle from '../PagesTitle/PagesTitle';
import Bizcard from '../Bizcard/Bizcard';
import PageTitleScreen from '../PageTitleScreen/PageTitleScreen';

enum RevealState {
    hidden = "hidden",
    revealed = "revealed",
    unrevealed = "unrevealed"
}


interface Props {

}

interface State {
    ratio:number
}



export default class Contact extends React.Component<Props, State>{

    constructor(props:Props){
        super(props)
        this.state = {
            ratio: 0
        }
    }

    handlePage2Intersect = (element:any, ratio:number, direction:RatioDirection, rect:any) => {
        // console.log(" - ratio = " + ratio)
        // if(direction === RatioDirection.increasing){
            this.setState({ratio})
        // }
    }


    handleTitleClick = () => {
        Tools.scrollIntoView("contact-page-2")
        // const el = document.getElementById("contact-page-2");
        // if(el) el.scrollIntoView();
    }

    

    render(){

        
        return(
            <section id={NavRouteId.contact}>
                
                <ScreenAnchor routeId={NavRouteId.contact}/>

                <div id="contact-bg"></div>

                <div id="experience-content" className="content">

                     {/* PAGE TITLE SCREEN */}
                    <PageTitleScreen
                        id="contact"
                        label="Contact"
                        onClick={this.handleTitleClick}
                    />

                    <div id="contact-pages" className="pages">

                        {/* PAGES TITLE */}
                        <PagesTitle id="contact" label="Contact" />
                        
                        {/* PAGE 2 */}
                        <Intersector 
                            onIntersect={this.handlePage2Intersect}
                            numSteps={100}
                            inViewAt={0.01}
                            outOfViewAt={0.7}
                        >
                        <div id="contact-page-2" className="page page2">

                            <div className='content-container'>

                                 <div className='line-container'>
                                    <div className='line' style={ { height: (this.state.ratio * 30) + "vh" } } />
                                </div>

                                <div className='main-column'>
                                    <div className='content main-column-content'>
                                        
                                   

                                    <h1>Drop me a line.</h1>
                                    
                                    <Bizcard
                                        id="contact_bizcard"
                                        bubbleLabel={"Thanks :)"}
                                        description={null}
                                    />

                                    <p>Get in touch at <a href="mailto:darri@playlingo.co">darri@playlingo.co</a> &mdash; I'd love to hear from you.
                                     {/* or DM me on Twitter <a href="https://twitter.com/darri">@darri</a> */}
                                     </p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        </Intersector>
                    </div>
                    
                </div>
                
                
            </section>
            
        )
    }
}
import React from 'react';
import './_styles.scss';

// import App from '../../App';
// import Tools from '../../tools/Tools';


interface Props {
    onClick:Function,
    isOpen:boolean,
}

interface State{
    // isOpen:boolean;
}



export default class Nav extends React.Component<Props, State> {
    handleClick=()=>{
        if(this.props.onClick) this.props.onClick();
    }

    render(): React.ReactNode {

        let containerClass="hamburger";
        if(this.props.isOpen) containerClass += " is-open";
        else{ containerClass += " is-closed"}

        return(
            <div 
                id="hamburger" 
                className={containerClass}
                onClick={this.handleClick}
            >

                <div className="burger-icon">
                    <div className="burger-container">
                        <span className="burger-bun-top bun-line"></span>
                        <span className="burger-filling bun-line"></span>
                        <span className="burger-bun-bot bun-line"></span>
                    </div>
                </div>
               
                
            </div>
        )
    }
}
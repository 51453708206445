import React from 'react';
import './_styles.scss';

// import App from '../../App';
import Tools from '../../tools/Tools';
import Intersector, {InitPosition, InitView, RatioDirection} from '../Intersector/Intersector';
// import $ from 'jquery';
import { NavRouteId } from '../Nav/Nav';
import Poll, {PollData, PollResultBarSize, PollResultLabelSize} from '../Poll/Poll';
import Bizcard from '../Bizcard/Bizcard';
import PagesTitle from '../PagesTitle/PagesTitle';
// import SectionIntersector from '../SectionIntersector/SectionIntersector';
import ScreenAnchor from '../ScreenAnchor/ScreenAnchor';
import PageTitleScreen from '../PageTitleScreen/PageTitleScreen';

enum RevealState {
    hidden = "hidden",
    revealed = "revealed",
    unrevealed = "unrevealed"
}


interface Props {

}

interface State {
    page3RevealState:RevealState
}


export default class Skills extends React.Component<Props,State>{

    skillsPoll:Poll;
    skillsDevPoll:Poll;
    skillsUxdPoll:Poll;
    isPage3PollResultsRevealed = false;

    poll:PollData = {
        id:"skills",
        options:[
            {id:"html", label:"HTML / CSS"},
            {id:"java", label:"Java"},
            {id:"javascript", label:"Javascript"},
            {id:"python", label:"Python"},
            {id:"sql", label:"SQL"},
            {id:"typescript", label:"TypeScript"},
            {id:"other", label:"Other"},
        ],

        results:[
            {id:"javascript", label:"Javascript", value:68.6, valueMax:69, unit:"%"},
            {id:"html", label:"HTML / CSS", value:55.9, valueMax:69, unit:"%"},
            {id:"sql", label:"SQL", value:50.7, valueMax:69, unit:"%"},
            {id:"python", label:"Python", value:41.5, valueMax:69, unit:"%"},
            {id:"typescript", label:"TypeScript", value:36.4, valueMax:69, unit:"%"},
            {id:"java", label:"Java", value:34.5, valueMax:69, unit:"%"},
        ],
        
    }

    skillsDevData:PollData = {
        id:"skills_dev",
        results:[
            {id:"javascript", label:"JavaScript", value:95},
            {id:"html", label:"HTML", value:92},
            {id:"css", label:"CSS / SCSS", value:85},
            {id:"typescript", label:"TypeScript", value:90},
            {id:"wordpress", label:"Wordpress", value:80},
            {id:"reactnative", label:"React Native", value:85},
            {id:"reactjs", label:"React JS", value:87},
            {id:"accessibility", label:"Accessibility", value:82},
            {id:"testing", label:"Automated testing", value:65},
            {id:"git", label:"Version control / GIT", value:78},
            {id:"agile", label:"Agile development", value:69},
            {id:"firebase", label:"Firebase / A-B testing", value:71},
            {id:"_group", label:"...and some back-end skills too!", value:0},
            {id:"node", label:"Node.js", value:53},
            {id:"sql", label:"MySQL / SQLite", value:55},
            {id:"mongo", label:"MongoDB", value:45},
            {id:"php", label:"PHP", value:50},

        ],
        
    }

    skillsUxdData:PollData = {
        id:"skills_uxd",
        results:[
            // {id:"collaborative_design", label:"Collaborative design", value:85},
            {id:"user_centered_design", label:"User centered design", value:88},
            {id:"user_personas", label:"User personas", value:85},
            {id:"user_stories", label:"User stories", value:87},
            {id:"user_testing", label:"User testing", value:75},
            {id:"uidesign", label:"UI Design", value:89},
            {id:"branding", label:"Branding", value:75},
            {id:"vectorillustration", label:"Vector illustration", value:77},
            {id:"pixelart", label:"Pixel art", value:82},
            {id:"3d", label:"3D modelling", value:75},
            {id:"prototyping", label:"Fast prototyping", value:70},
            {id:"_group", label:"Software", value:0},
            {id:"figma", label:"Figma", value:95},
            {id:"sketch", label:"Sketch", value:91},
            {id:"adobe_ps", label:"Adobe Photoshop", value:100},
            {id:"adobe_an", label:"Adobe Animate", value:98},
            {id:"adobe_il", label:"Adobe Illustrator", value:85},
            {id:"adobe_ae", label:"Adobe After Effects", value:75},
            {id:"blender", label:"Blender", value:75},

        ],
        
    }


    page1Bg:any;
    page1Title:any;
    pagesTitle:any;
    
    // increasingColor = "rgba(120, 40, 80, ratio)";

    constructor(props:Props){
        super(props);
        this.state = {
            page3RevealState: RevealState.revealed
        }
    }
   
    
    componentDidMount(): void {
        // this.statbar1 = document.querySelector("#section2Statbar1")
        
        this.page1Bg = document.querySelector("#skills .page1-bg")
        this.page1Title = document.querySelector("#skills .screen-title")
        
        
    }
    
    handlePage3Intersect = async (element:any, ratio:number, direction:RatioDirection, rect:any) => {
        // console.log("Skills.handlePage3Intersect()")
        if(this.state.page3RevealState === RevealState.revealed && !this.isPage3PollResultsRevealed){

            this.isPage3PollResultsRevealed = true;

            // await Tools.later(0.2);
            this.skillsDevPoll.revealResults();

            // await Tools.later(0.2);
            this.skillsUxdPoll.revealResults();
        }
    }

    
    /**
     * User clicked an option in the poll
     * @param id - result id 
     */
    // handleItemClick = (id:string) => {
    //     this.skillsPoll.scrollToResults();

    //     this.setState({page3RevealState:RevealState.revealed});
    // }


    handleTitleClick = () => {
        Tools.scrollIntoView("skills-page3");
        // const el = document.getElementById("skills-page3");
        // if(el) el.scrollIntoView();
    }

    render(){



        let page3Class = "page page3";
        if(this.state.page3RevealState === RevealState.revealed) page3Class += " revealed";

        return(
            <section id={NavRouteId.skills}>
                <ScreenAnchor routeId={NavRouteId.skills}/>
                
                
                {/* <SectionIntersector
                    id={"skills"}
                /> */}

                
                <div id="skills-content" className="content">

                    {/* PAGE TITLE SCREEN */}
                    <PageTitleScreen
                        id="skills"
                        label="Skills"
                        onClick={this.handleTitleClick}
                    />
                   
                    <div id="skills-pages" className="pages">

                        {/* PAGES TITLE */}
                        <PagesTitle id="skills" label="Skills" />


                        {/* PAGE 2 */}
                        {/* <div id="skills-page-2" className="page page2">
                            
                            <div className='main-column bg-image-container'>
                                <div className='bg-image'><img src="/images/skills/bg.jpg" alt=''/></div>
                            </div>
                            <div className='content-container'>
                                <div className='main-column'>
                                    <div className='content main-column-content'>
                                        
                                        <Bizcard
                                            id="skills_bizcard1"
                                            // bubbleLabel={"A quick 'skills' question..."}
                                            bubbleLabel={(<span>A quick <em>skills</em> question...</span>)}
                                        />
                                        <Poll
                                            ref={(ref)=>{if(ref) this.skillsPoll = ref}}
                                            onItemClick={this.handleItemClick}
                                            data={this.poll}
                                            // showResults={true}
                                            question={(<h1 className="small"><b>Question: </b>Which of these programming languages is used by the most programmers?</h1>)}
                                            answer={(<h1 className="small"><b>Most commonly used programming language<span className='faint'>*</span>:</b></h1>)}
                                            footnote={(<p className='footnote'>*Source: <a href="https://insights.stackoverflow.com/survey/2021#technology-most-popular-technologies">https://insights.stackoverflow.com/survey/2021#technology-most-popular-technologies</a></p>)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* PAGE 3 */}
                        <Intersector 
                            onIntersect={this.handlePage3Intersect}
                            inViewAt={0.2}
                            outOfViewAt={0.1}
                        >
                        <div id="skills-page3" className={page3Class}>
                            <div className='main-column bg-image-container'>
                                <div className='bg-image'><img src="/images/skills/bg.jpg" alt=''/></div>
                            </div>
                            <div className='content-container'>
                                <div className='main-column'>
                                    <div className='content main-column-content'>

                                    
                                      
                                        <div className="group group-dev">
                                            <header>
                                                <div className='pic'>
                                                    <img src="/images/skills/skills-dev.png" alt=''/>
                                                </div>
                                                <h1 className="small"><b>Front-end dev skills</b></h1>
                                            </header>
                                            <Poll
                                                ref={(ref)=>{if(ref) this.skillsDevPoll = ref}}
                                                data={this.skillsDevData}
                                                showResults={true}
                                                resultLabelSize={PollResultLabelSize.small}
                                                resultBarSize={PollResultBarSize.small}
                                                revealManually={true}
                                            />
                                        </div>

                                        <div className="group group-uxd">
                                            <header>
                                                <div className='pic'>
                                                    <img src="/images/skills/skills-uxd.png" alt=''/>
                                                </div>
                                                <h1 className="small"><b>UX design skills</b></h1>
                                            </header>
                                            <Poll
                                                ref={(ref)=>{if(ref) this.skillsUxdPoll = ref}}
                                                data={this.skillsUxdData}
                                                showResults={true}
                                                resultLabelSize={PollResultLabelSize.small}
                                                resultBarSize={PollResultBarSize.small}
                                                revealManually={true}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        </Intersector>
                    </div>
                </div>
            </section>
        
        )
    }
}
import React from 'react';
import {NavRouteId} from '../Nav/Nav';
import Intersector, {RatioDirection} from '../Intersector/Intersector';
import App from '../../App';
import './_styles.scss';

interface Props{
    routeId:NavRouteId
}

/**
 * Updates the Nav when the screen is onscreen
 */
export default class ScreenAnchor extends React.Component<Props>{

    handleAnchorTopIntersect = (element:any, ratio:number, direction:RatioDirection, rect:any) => {
        if(ratio <= 0 && rect.y < 0){
            App.routeToScreen(this.props.routeId, false);
        }
    }


    handleAnchorBottomIntersect = (element:any, ratio:number, direction:RatioDirection, rect:any) => {
        if(ratio > 0  && rect.y > 0 ) App.routeToScreen(this.props.routeId, false);
    }

    render(): React.ReactNode {
        return(
            <div>
                <Intersector 
                    onIntersect={this.handleAnchorTopIntersect}
                    // inViewAt={0.99}
                    // outOfViewAt={0.01}
                >
                    <div id={this.props.routeId + "-anchor"} className='screen-anchor'/>
                </Intersector>  
                
                <Intersector 
                    onIntersect={this.handleAnchorBottomIntersect}
                >
                    <div id={this.props.routeId + "-anchor-bottom"} className='screen-anchor bottom'/>
                </Intersector>  
            </div>
        )
    }
}
import React from 'react';
import _ from 'lodash';

import './_styles.scss';
import Screen from '../../tools/Screen';
import Tools from '../../tools/Tools';
import Intersector, {InitPosition, InitView, RatioDirection} from '../Intersector/Intersector';
import Bizcard, {BizcardLabelColor} from '../Bizcard/Bizcard';

const log = (...args:any) => { if(isLoggingOn) console.log(...args)}
const isLoggingOn = false;

interface Props{
    id:string;
    label:string,
    onIntersect?:Function,
    onClick?:Function
}
export default class PageTitleScreen extends React.Component<Props>{
    
    isRevealed:boolean;
    handleIntersect = (element:any, ratio:number, direction:RatioDirection, rect:any) => {
        // log("PageTitleScreen.handleIntersect()");
        // log(" - ratio = " + ratio);
        // log(" - id = " + this.props.id)
        if(ratio > 0 && !this.isRevealed){
            this.isRevealed = true
            this.reveal()
        }

        if(ratio <= 0 && this.isRevealed){
            this.isRevealed = false;
            this.unreveal()
        }

        if(this.props.onIntersect) this.props.onIntersect(element, ratio, direction, rect)

        
    }

    rotateShape(num:number){
        const rotationMin = 90;
        const rotationMax = 180;
        const el = document.getElementById(this.getShapeId(num) + "-inner-rotate");
        
        //@ts-ignore
        let rot:number = el.style.transform === "" ? 0 : Number(el.style.transform.match(/\d+/));
        let rotInc = Math.round((rotationMin + (Math.random() * (rotationMax - rotationMin)) ) );
        rotInc = Math.random() > 0.5 ? rotInc : -rotInc;
        rot += rotInc;
        el.style.transform = "rotate(" + rot + "deg)";
    }

    positionShape(num:number, pos:string){
        const shapeSize = 250;
        const vh = Screen.screenHeight;
        const vw = Screen.screenWidth;
        const paddingTopBottom = vh * 0.3;
        const paddingLeftRight = vw * 0.3;
        const el = document.getElementById(this.getShapeId(num));

        if(pos === "left"){
            el.style.left = -(shapeSize/5) + "px";
            el.style.right = el.style.bottom = "unset";
            el.style.top = paddingTopBottom + ((vh - (2 * paddingTopBottom)) * Math.random())  - (shapeSize/2) + "px";
        }

        else if(pos === "bottom"){
            el.style.top = el.style.right = "unset";
            el.style.bottom =  -(shapeSize/4) + "px";
            el.style.left = paddingLeftRight + ((vw - (2 * paddingLeftRight)) * Math.random()) - (shapeSize/2) + "px";
        }

        else if(pos === "right"){
            el.style.left = el.style.bottom = "unset";
            el.style.top = paddingTopBottom + ((vh - (2 * paddingTopBottom)) * Math.random())  - (shapeSize/2) + "px";
            el.style.right = -(shapeSize/5) + "px";
        }
    }

    reveal = async () => {
        // log("PageTitleScreen.reveal()");
        // log(" - id = " + this.props.id)
        log(" ")
        
        let positions = ["left", "bottom", "right"];
        positions = _.shuffle(positions);
        // log(" - position = " + positions)

        // Triangle
        this.positionShape(1, positions[0])
        this.rotateShape(1);
        
       
        // Circle
        this.positionShape(2, positions[1])
        this.rotateShape(2);

        // // Square
        this.positionShape(3, positions[2])
        this.rotateShape(3);
        
        
        
        
        
    }


    unreveal(){
        // log("PageTitleScreen.unreveal()");
        // log(" - id = " + this.props.id)
    }

    get shape1Id ():string{return this.getShapeId(1)}
    get shape2Id ():string{return this.getShapeId(2)}
    get shape3Id ():string{return this.getShapeId(3)}

    getShapeId(num:number):string{
        return (this.props.id + "-title-shape" + num)
    }


    handleTitleClick = () => {
        if(this.props.onClick) this.props.onClick()
    }



    render(): React.ReactNode {
        return(
            <Intersector 
                addClasses={true}
                inViewAt={0.01}
                outOfViewAt={0.01}
                initView={InitView.out}
                onIntersect={this.handleIntersect}
            >
                <div id={this.props.id + "-page1"} className="page page1">
                    <div className='bg'></div>
                    
                    <Intersector 
                        addClasses={true}
                        inViewAt={0.01}
                        outOfViewAt={0.5}
                    >
                    <div id={this.shape1Id} className="shape shape1">
                        <div className="inner-top">
                            <div id={this.shape1Id + "-inner-rotate"} className="inner-rotate">
                                <img src="/images/common/shape-triangle.png" alt=''/>
                            </div>
                        </div>
                    </div>
                    </Intersector>

                    <Intersector 
                        addClasses={true}
                        inViewAt={0.01}
                        outOfViewAt={0.5}
                    >
                    <div id={this.shape2Id} className="shape shape2">
                        <div className="inner-top">
                            <div id={this.shape2Id + "-inner-rotate"} className="inner-rotate">
                                <img src="/images/common/shape-circle.png" alt=''/>
                            </div>
                        </div>
                    </div>
                    </Intersector>
                    
                    <Intersector 
                        addClasses={true}
                        inViewAt={0.01}
                        outOfViewAt={0.5}
                    >
                    <div id={this.shape3Id} className="shape shape3">
                        <div className="inner-top">
                            <div id={this.shape3Id + "-inner-rotate"} className="inner-rotate">
                                <img src="/images/common/shape-square.png" alt=''/>
                            </div>
                        </div>
                    </div>
                    </Intersector>

                
                    <div className="title-container">
                        <button onClick={this.handleTitleClick}>
                            <h1 className='title'>{this.props.label}</h1>
                            
                            {this.props.id === "experience" &&
                                <Bizcard
                                    id="experience_bizcard2"
                                    // bubbleLabel={"My work..."}
                                    description={"Here's a quick run-down of my experience and some of the projects I've worked on..."}
                                    labelColor={BizcardLabelColor.dark}
                                />
                            }

                            {this.props.id === "skills" &&
                                <Bizcard
                                    id="skills_bizcard2"
                                    bubbleLabel={"Hi!"}
                                    description={"Originally a designer working on fun web games, I’ve expanded what I do over the years and now bring a broad range of programming skills, workflow practices, UX design principles, and a well honed eye for good user experience to any project."}
                                />
                            }
                            <div className="dots">
                                <div className="dot dot1"/>
                                <div className="dot dot2"/>
                                <div className="dot dot3"/>
                                
                            </div>
                            
                        </button>
                        
                        
                    </div> 
                </div>

            </Intersector>
        )
    }
}
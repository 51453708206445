import React from 'react';
import './_styles.scss';

import Intersector, {InitPosition, InitView, RatioDirection} from '../Intersector/Intersector';
import GalleryImage, {GalleryImageData} from '../GalleryImage/GalleryImage';
import App from '../../App';
import Radio, {NavChangeEvent} from '../../tools/Radio';
import Tools from '../../tools/Tools';
import Viewer from 'viewerjs';

export type Badge = {
    id:BadgeId,
    alt?:string,
}
export enum BadgeId{
    bafta = "bafta",
    bima = "bima",
    elton = "elton",
    mobileApp = "mobile-app",
    webApp = "web-app",
    website = "website",
    prototype = "prototype",
    nms = "nms",
    amazon = "amazon",
    bbc = "bbc",
    scotgov = "scotgov",

}

export enum RoleId{
    frontendDev = "Front-end developer",
    leadFrontendDev = "Lead front-end developer",
    uxDesigner = "UX designer",
    leadUxDesigner = "Lead UX designer",
    uiDesigner = "UI designer",
    illustrator = "Illustrator",
    animator = "Animator",

}

// const roles = {
//     frontendDev: "Front-end Developer",

// }

interface Props{
    id:string,
    type?:string,
    title:string,
    images:GalleryImageData[],
    summary:string,
    roles:RoleId[],
    description:string,
    skills:string,
    badges?:Badge[],
    screenWidth:number;
    
}
interface State{
    galleryWidth:number
}

export default class GalleryPage extends React.Component<Props, State>{

    el:any;
    isRevealed:boolean = false;
    isNavigating:boolean = false;

    constructor(props:Props){
        super(props);
        this.state = {
            galleryWidth: this.galleryWidth
        }

        App.radio.addListener(Radio.NAV_CHANGE, this.handleNavChange, this)
    }


    // --------------------------------------------------------------------------
    // LIFECYCLE

    componentDidMount(): void {
        this.el = document.getElementById(this.galleryElementId);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        const galleryWidth = this.galleryWidth;
        
        if(this.state.galleryWidth !== galleryWidth){
            this.setState({galleryWidth})
        }
    }

    // --------------------------------------------------------------------------
    // METHODS

    get galleryWidth():number{
        try{
            const el = document.getElementById(this.galleryElementId + "-images")
            const w = el.getBoundingClientRect().width;
            return w;
        }catch(err){
            return 0;
        }
    }

    get galleryElementId ():string{
        return "gallery-" + this.props.id
    }

    
    reveal(){
        // if(this.isNavigating) return;
        if(App.isScrollingToScreen) return;
        if(this.isRevealed === true) return;
        this.isRevealed = true;
        this.el.classList.add("in-view")
        this.el.classList.remove("out-of-view")
    }

    /**
     * Add the "out-of-view" class to the GalleryPage element
     * @param force Run function even when isRevealed is false
     * @returns 
     */
    unreveal(force:boolean = false){
        try{
            if(this.isRevealed === false && !force) return;

            this.isRevealed = false;
            
            this.el.classList.add("out-of-view")
            this.el.classList.remove("in-view")
        }catch(err){

        }
    }

    // --------------------------------------------------------------------------
    // HANDLERS

    /**
     * Unreveal whenever the nav changes
     */
    handleNavChange = async (evt:NavChangeEvent) => {
        this.unreveal(true);
        this.isNavigating = true;
        await Tools.later(0.5);
        this.isNavigating = false;
    }

    handleTopIntersect = (element:any, ratio:number, direction:RatioDirection, rect:any) =>{
        
        // Unreveal if top line is going off the bottom
        if(this.isRevealed){
            if(direction === RatioDirection.decreasing && rect.bottom >= window.innerHeight){
                // console.log(" - " + this.props.id + " meets condition 1, unreveal")
                this.unreveal()
            }
        }
        // Reveal if top line is coming up from bottom
        else{
            if(
                direction === RatioDirection.increasing
                && ratio > 0
                // && rect.bottom > window.innerHeight
            ){
                // console.log(" - " + this.props.id + " meets condition 2, reveal")
                this.reveal()
            }
        }
    }

    handleBottomIntersect = (element:any, ratio:number, direction:RatioDirection, rect:any) =>{
 
        // Unreveal if bottom line is going off the top
        if(this.isRevealed){
            if(rect.bottom <= window.innerHeight){
                // console.log(" - " + this.props.id + " meets condition 3, unreveal")
                this.unreveal()
            }
        }

        // Reveal if bottom line is going off the bottom
        else{
            if(rect.bottom >= window.innerHeight && direction === RatioDirection.decreasing){
                // console.log(" - " + this.props.id + " meets condition 4, reveal")
                this.reveal()
            }
        }
        
        
        
    }

    

    handleImageClick = (elId:string) => {
        
        
        // console.log("GalleryPage.handleClick()")
        
        const el = document.getElementById(elId);
        // await Tools.later(1);
        
        const viewer = new Viewer(el, {
            inline: false,
            toolbar: false,
            navbar: false,
            title:false,
            // viewed() {
            //     viewer.zoomTo(1);
            // },
        });
        viewer.view(0)
    }


    get rolesText():any[]{
        let els:any = [];
        
        this.props.roles.map((role:RoleId, index:number) => {
            const el = (
                <span 
                    key={this.props.id + "_role_" + index}
                >
                    <span>{role}</span>
                    {index < this.props.roles.length - 1 &&
                        <span>, </span>
                    }
                </span>
            )
            els.push(el);
            return null;
        })


        return els;
    }
    // --------------------------------------------------------------------------
    // RENDER

    render(): React.ReactNode {

        const type = this.props.type || "Project";

        
        return(

            <div className="gallery-container">
            
            
                <div id={this.galleryElementId} className="page gallery-page out-of-view">

                    <Intersector inViewAt={0.9} outOfViewAt={0.9} onIntersect={this.handleTopIntersect}><div className="gallery-page-anchor top" id={this.galleryElementId + "-anchor-top"}/></Intersector>
                    <Intersector inViewAt={0.1} outOfViewAt={0.9} onIntersect={this.handleBottomIntersect}><div className="gallery-page-anchor bottom" id={this.galleryElementId + "-anchor-bottom"}/></Intersector>

                    <div className='content-container'>
                        <div className='main-column'>
                            <div className='content main-column-content'>

                                <div className='text-container'>
                                    <div className='text'>
                                        
                                        {/* TYPE */}
                                        <div className="type smallcaps">{type}</div>
                                        
                                        {/* TITLE */}
                                        <h2>{this.props.title}</h2>

                                        {/* BADGES */}
                                        <div className="badges">
                                            {this.props.badges && this.props.badges.map((badge:Badge, index:number)=>{
                                                return(
                                                    <div key={this.galleryElementId + "_badge_" + index} className="badge">
                                                        <div className="icon">
                                                            <img src={`/images/common/badges/${badge.id}-eggshell.png`} alt={badge.alt ? `${badge.alt}` : ""}/>
                                                        </div> 
                                                    </div>
                                                )
                                            })
                                        }
                                            
                                           
                                        </div>

                                        {/* SUMMARY */}
                                        <p className="summary">{this.props.summary}</p>

                                        {/* DESCRIPTION */}
                                        <p className="description">{this.props.description}</p>

                                        {/* ROLES */}
                                        <h3 className='smallcaps skills-title'>ROLES</h3>
                                        <p className="skills">{this.rolesText}</p>
                                        
                                        {/* SKILLS */}
                                        <h3 className='smallcaps skills-title'>SKILLS &amp; TECHNOLOGY</h3>
                                        <p className="skills">{this.props.skills}</p>

                                    </div>
                                </div>
                                
                                <div className="images" id={this.galleryElementId + "-images"}>
                                    {this.props.images.map((data:GalleryImageData, index:number) => {
                                        // return null
                                        return(
                                            
                                                <GalleryImage
                                                    key={this.galleryElementId + "_image_" + index} 
                                                    galleryId={this.props.id}
                                                    imageData={data}
                                                    index={index}
                                                    galleryWidth={this.state.galleryWidth}
                                                    onClick={this.handleImageClick}
                                                />
                                        )
                                    })}
                                
                                    
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
                </div>
            
            
            </div>
    
    
        )
    }
}
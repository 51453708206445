import React from 'react';


import HomeBgIcon from './HomeBgIcon';
import Screen from '../../tools/Screen';
import Intersector from '../Intersector/Intersector';
import './_styles.scss';


const log = (...args:any) => { if(isLoggingOn) console.log(...args)}
const isLoggingOn = true;

export interface Item{
    id:string, 
    imageId:string,
    pos:Pos
}
export interface Pos{
    x:number,
    y:number
}

interface Props{
}

interface State{
    items:Item[]
}
export default class HomeBg extends React.Component<Props,State>{

    constructor(props:Props){
        super(props);

        // console.log("HomeBg()")

        let items:Item[] = [
            {id: "bug", imageId: "bug", pos:{x:0,y:0}},
            {id: "coffee", imageId: "coffee", pos:{x:0,y:0}},
            {id: "desktop", imageId: "desktop", pos:{x:0,y:0}},
            {id: "mobile", imageId: "mobile", pos:{x:0,y:0}},
            {id: "robot", imageId: "robot", pos:{x:0,y:0}},
            {id: "testing", imageId: "testing", pos:{x:0,y:0}},
            {id: "db", imageId: "db", pos:{x:0,y:0}},

            // {id: "bug2", imageId: "bug", pos:{x:0,y:0}},
            {id: "coffee2", imageId: "coffee", pos:{x:0,y:0}},
            {id: "desktop2", imageId: "desktop", pos:{x:0,y:0}},
            {id: "mobile2", imageId: "mobile", pos:{x:0,y:0}},
            {id: "robot2", imageId: "robot", pos:{x:0,y:0}},
            {id: "testing2", imageId: "testing", pos:{x:0,y:0}},
            {id: "db2", imageId: "db", pos:{x:0,y:0}},
            
        ]
       
        items = this.getNewItems(items, "bug");
        items = this.getNewItems(items, "coffee");
        items = this.getNewItems(items, "desktop");
        items = this.getNewItems(items, "mobile");
        items = this.getNewItems(items, "robot");
        items = this.getNewItems(items, "testing");

        items = this.getNewItems(items, "bug2");
        items = this.getNewItems(items, "coffee2");
        items = this.getNewItems(items, "desktop2");
        items = this.getNewItems(items, "mobile2");
        items = this.getNewItems(items, "robot2");
        items = this.getNewItems(items, "testing2");

        this.state = {
            items:items
        }
    }

    get iconSize():number{
        if(Screen.isScreenBreakpointSmallDown) return 100;
        return 150;
    }

    _paddingX:number;
    get paddingX():number{
        if(this._paddingX !== undefined) return this._paddingX;
        if(Screen.isScreenBreakpointSmallDown) this._paddingX = 0;
        else this._paddingX = Screen.screenWidth * 0.2;
        return this._paddingX;
    }

    _paddingY:number;
    get paddingY():number{
        if(this._paddingY !== undefined) return this._paddingY;
        if(Screen.isScreenBreakpointSmallDown) this._paddingY = 0;
        else this._paddingY = Screen.screenHeight * 0.2;
        return this._paddingY;
    }


    
    getNewItems = (items:Item[], itemId:string):Item[] => {

        if(!items) return;

        let pos:Pos;
        const iconSize = this.iconSize;
        items = [...items];

        const tryPos = (index:number = 0) => {
            
            pos = this.getRandomPos(); 
            let isOverlapping:boolean = false;
            
            // Loop through all items and check if overlapping
            for(let item of items){
                const itemRect = {left: item.pos.x, right: item.pos.x + iconSize, top: item.pos.y, bottom: item.pos.y + iconSize}
                const newRect = {left: pos.x, right: pos.x + iconSize, top: pos.y, bottom: pos.y + iconSize}
                if(
                    newRect.left > itemRect.left - iconSize
                    && newRect.left < itemRect.right
                    && newRect.top > itemRect.top - iconSize
                    && newRect.top < itemRect.bottom
                ){
                    isOverlapping = true;
                    break
                }
            }

            if(isOverlapping){
                // log(" - isOverlapping")
                index++;
                tryPos(index);
            }else{
                return pos;
            }
        }

        try{
            tryPos();
        }catch(err){
            console.log("No space to render icons")
            return;
        }
        
        for(let item of items){
            if(item.id === itemId) item.pos = pos;
        }
        
        return items;
    }

    setPos = (itemId:string) => {
        let newItems:Item[] = this.getNewItems(this.state.items, itemId)
        this.setState({items: newItems})
    }
    
    getRandomPos():Pos{
        const iconSize = this.iconSize;
        const paddingX:number = this.paddingX;
        const paddingY:number = this.paddingY;
        const x = Math.floor(paddingX + ( ( Screen.screenWidth - ( 2 * paddingX ) ) * Math.random() ) - (iconSize/2));
        const y = Math.floor(paddingY + ( ( Screen.screenHeight - ( 2 * paddingY ) ) * Math.random() ) - (iconSize/2));
        return {x:x, y:y};
    }

    handleItemPingComplete = (itemId:string) => {
        this.setPos(itemId);
    }

    render(): React.ReactNode {

        if(!this.state.items) return null;
        return(
            <Intersector
                addClasses={true}

            >
            <div id="home-bg" className="home-bg">
                {this.state.items.map((item:Item, index) => {
                    return(
                        <HomeBgIcon
                            key={"home-bg-icon" + index}
                            id={item.id}
                            imageId={item.imageId}
                            pos={item.pos}
                            onPingComplete={this.handleItemPingComplete}
                        />
                    )
                })}
            </div>
            </Intersector>
        )
    }
}
import React from 'react';
import './_styles.scss';
import {Link} from 'react-router-dom';

import App from '../../App';
import Tools from '../../tools/Tools';
import HamburgerBut from '../HamburgerBut/HamburgerBut';

interface NavRoute  {
    id:NavRouteId,
    label:string,
    visible?:boolean
}

export enum NavRouteId {
    home="home",
    skills="skills",
    experience="experience",
    contact="contact",
    error="error",
}


interface Props {
    routeId?:NavRouteId,
    screenWidth:number,
}

interface State{
    isOpen:boolean;
}

let instance:Nav;

export default class Nav extends React.Component<Props, State> {
    routes:NavRoute[] = [
        {id:NavRouteId.home, label:"Home"}, 
        {id:NavRouteId.skills, label:"Skills"}, 
        {id:NavRouteId.experience, label:"Experience"}, 
        {id:NavRouteId.contact, label:"Contact"},
        {id:NavRouteId.error, label:"Error", visible:false} 
    ]
    

    constructor(props:Props){
        super(props);
        instance = this;

        this.state = {
            isOpen: false
        }

    }
    
    // componentDidMount(): void {
    //     if(Nav.isValidRouteId(this.props.routeId)) this.selectRoute(this.props.routeId)
    // }
    
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if(prevProps.routeId !== this.props.routeId && Nav.isValidRouteId(this.props.routeId)){
            this.selectRoute(this.props.routeId)
        }

        if(prevProps.screenWidth !== this.props.screenWidth){
            this.refresh()
        }
    }

    

    static isValidRouteId(routeId:string):boolean{
        // console.log(`Nav.isValidRouteId(${routeId})`)
        if( 
            routeId == NavRouteId.home ||
            routeId == NavRouteId.skills ||
            routeId == NavRouteId.experience ||
            routeId == NavRouteId.contact || 
            routeId == NavRouteId.error 
        ){
            return true;
        }
        return false;
    }

    static selectRoute(routeId:NavRouteId){
        instance.selectRoute(routeId);
    }

    selectedRouteId:NavRouteId|null;

    /**
     * Select a route in the nav list
     * @param routeId 
     * @param isRefreshing - Set to true is refreshing nav (e.g. when screen has been resized) 
     * @returns 
     */
    selectRoute(routeId:NavRouteId, isRefreshing:boolean = false){
        try{
            // if(this.props.routeId === routeId) return;
            if(routeId === NavRouteId.error){
                // throw new Error("Error route");
                return;
            }

            // Same route and not refreshing, return now.
            if(this.selectedRouteId === routeId && !isRefreshing){
                return;
            }

          
            this.selectedRouteId = routeId;

            const navEl = document.getElementById("nav");
            const navRect = navEl.getBoundingClientRect();
        
            const itemEl = document.getElementById("nav_label_" + routeId);
            const itemRect = itemEl.getBoundingClientRect();

            // Get x positions. IE uses 'left' instead of 'x'.
            const newX = itemRect.x === undefined ? itemRect.left - navRect.left : itemRect.x - navRect.x;
            
            const newWidth = itemRect.width;
            const barEl = document.getElementById("navbar");
            barEl.style.transform = `translateX(${newX}px)`;
            barEl.style.width = `${newWidth}px`;
            
        }catch(err){
            console.log(`Error Nav.selectRoute(${routeId}): `, err)
        }
    }

    refresh(){
        const routeId:NavRouteId = this.props.routeId || NavRouteId.home;
        this.selectRoute(routeId, true);
    }

    getUrl(routeId:NavRouteId):string{
        return "/" + routeId;
    }

    handleClick = (routeId:NavRouteId, e?:any )=>{
        // console.log(`Nav.handleClick(${routeId})`)
        // if(e) e.preventDefault();
        
        if(!App.isScrollingToScreen) App.routeToScreen(routeId);   
        
        this.setState({isOpen:false})
    }

    handleMouseOver = (routeId:NavRouteId, e?:any )=>{
        // console.log(`Nav.handleHover(${routeId})`)
        if(e) e.preventDefault();
        if(!Tools.isTouchDevice) this.selectRoute(routeId)
        
    }
    handleMouseOut = (routeId:NavRouteId, e?:any )=>{
        if(!Tools.isTouchDevice) this.selectRoute(this.props.routeId)
    }

    handleHamburgerClick = () => {
        // console.log("Nav.handleHamburgerClick()")
        let isOpen:boolean = !this.state.isOpen;
        this.setState({isOpen});
    }

    render() {
        let containerClass= "";
        if(this.state.isOpen) containerClass += "nav-open";

        return (
            <div className="nav-container">
            {/* <div className='nav-bg'>
                <div className='nav-col nav-col-bg-grey'/>
                <div className='nav-col nav-col-cyan'/>
                <div className='nav-col nav-col-dark'/>
                <div className='nav-col nav-col-light'/>
                <div className='nav-col nav-col-pink'/>
                <div className='nav-col nav-col-teal'/>
                <div className='nav-col nav-col-yellow'/>
            </div> */}
            <nav id="nav" className={containerClass}>
                
                <ul>
                    {this.routes.map((route:NavRoute)=>{
                        if(route.visible === false) return null;
                        let itemClass = "item item-" + route.id;
                        
                        return(
                            <li 
                                key={"nav_" + route.id}
                                id={"nav_" + route.id}
                            >
                                <Link 
                                    className={itemClass}
                                    to={this.getUrl(route.id)} 
                                        onClick={(e:any)=>{this.handleClick(route.id, e)}}
                                        onMouseOver={(e:any)=>{this.handleMouseOver(route.id, e)}}
                                        onMouseOut={(e:any)=>{this.handleMouseOut(route.id, e)}}
                                    >
                                        <span id={"nav_label_" + route.id}>{route.label}</span>
                                        {/* <span className='item-border'/> */}
                                    
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>

                    {/* <button 
                        className="hamburger"
                        onClick={this.handleHamburgerClick}
                    /> */}
                    <HamburgerBut
                        onClick={this.handleHamburgerClick}
                        isOpen={this.state.isOpen}

                    />

                    


                    <div id="navbar"/>

                    
                    
                </nav>
            </div>
        );
    }
}
import React from 'react';

import './_styles.scss';

import Tools from '../../tools/Tools';

interface Props{
    isReady:boolean
}

interface State{
    isHidden:boolean
}

export default class Preloader extends React.Component<Props, State>{
    
    isHiddenTimerBegun:boolean = false;

    constructor(props:Props){
        super(props)
        this.state = {
            isHidden:false
        }
    }
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        const shouldHide = (!prevProps.isReady && this.props.isReady && !this.isHiddenTimerBegun);
        if(shouldHide){
            this.isHiddenTimerBegun = true;
            this.hide(1)
        }
    }

    async hide(delaySecs:number = 0){
        if(delaySecs) await Tools.later(delaySecs);

        this.setState({isHidden:true})
    }
    render(): React.ReactNode {


        if(this.state.isHidden) return null;

        let containerClass = "preloader";
        if(this.props.isReady) containerClass += " unrevealed"
        return (
            <div className={containerClass}>
                
            </div>
        )
    }
}
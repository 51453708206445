import React from 'react';
import './_styles.scss';
interface Props{
    // modalId:string
}
export default class ModalThanks extends React.Component<Props>{

   
    render(){
        return(
            <div className="modal-thanks-content">
                <h1>
                    Thanks      
                </h1>
                <p>
                    Thanks to the designers at the always handy <a href="https://thenounproject.com/">NounProject.com</a> for use of their work:
                </p>
                <ul>
                    <li>Acceptance by Eucalyp</li>
                    <li>Design by Flatart</li>
                    <li>Design by Kiran Shastry</li>
                    <li>Programming by Komkrit Noenpoempisut</li>
                    <li>EucalCat by Stepko Mutkoyp</li>
                

                </ul>

                <p>
                    And thanks to Mike &amp; Ziad for making an amazing team.
                </p>
            </div>
        );
    }
}
enum BreakpointId {
    xs = "xs",
    s = "s",
    m = "m",
    l = "l",
    xl = "xl",
    xlUp = "xl-up"
}

enum Breakpoint {
    xs = 360,
    s = 640,
    m = 1024,
    l = 1280,
    xl = 1400,
}


export default class Screen{

    static isScreenBreakpoint(bp:BreakpointId):boolean{
        return bp === this.breakpoint;
    }

    static isScreenBreakpointSmallDown():boolean{
        const bp = this.breakpoint
        return (bp === BreakpointId.xs || bp === BreakpointId.s);
    }

    static get breakpoint():BreakpointId{
        const w = this.screenWidth;
        if(w < Breakpoint.xs) return BreakpointId.xs;
        else if(w < Breakpoint.s) return BreakpointId.s;
        else if(w < Breakpoint.m) return BreakpointId.m;
        else if(w < Breakpoint.l) return BreakpointId.l;
        else if(w < Breakpoint.xl) return BreakpointId.xl;
        else return BreakpointId.xlUp;
    }

    static get screenWidth():number{
        return window.innerWidth
    }

    static get screenHeight():number{
        return window.innerHeight
    }

}
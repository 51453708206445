import React from 'react';
import Intersector, {InitPosition, InitView, RatioDirection} from '../Intersector/Intersector';

import './_styles.scss';

export enum BizcardLabelColor{
    light = "light",
    eggshell = "eggshell",
    dark = "dark",
}

interface Props{
    id:string,
    bubbleLabel?:any,
    description?:any,
    labelColor?:BizcardLabelColor
}

export default class Bizcard extends React.Component<Props>{


    render(): React.ReactNode {
        
        let picClass = "pic";
        if(this.props.bubbleLabel !== null) picClass += " with-bubble";
        const bubbleLabel = this.props.bubbleLabel || "Hi!";

        let containerClass = 'bizcard';
        if(this.props.labelColor) containerClass += " " + this.props.labelColor;

        return(

            <Intersector 
                // onIntersect={this.handlePage3Intersect}
                inViewAt={0.1}
                outOfViewAt={0.99}
                addClasses={true}
                initView={InitView.out}
            >
            <div id={this.props.id} className={containerClass}>
                
                <div className={picClass}>
                    <div className='container'>
                        <div className='pic-and-bubble'>

                            {/* BUBBLE */}
                            {this.props.bubbleLabel &&
                                <div className='bubble'><span>{bubbleLabel}</span></div>
                            }
                            <img src="/images/common/profile-pic.png" alt="Picture of Darri"/>
                        </div>
                    </div>
                </div>
                
                <div className='text'>
                    <span className='smallcaps name'>Darri Donnelly</span>
                    <span className='smallcaps roles'>Front-end developer &amp; UX Designer</span>
                    {this.props.description !== undefined &&
                        <p>{this.props.description}</p>
                    }
                </div>
            </div>
            </Intersector>
        )
    }
}
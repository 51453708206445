import React from 'react';
import './_styles.scss';
import Poll, {PollData} from '../Poll/Poll';
// import App from '../../App';
import Intersector, {InitPosition, InitView, RatioDirection} from '../Intersector/Intersector';
import Tools from '../../tools/Tools';
import { NavRouteId } from '../Nav/Nav';
import PagesTitle from '../PagesTitle/PagesTitle';
import ScreenAnchor from '../ScreenAnchor/ScreenAnchor';
// import Bizcard, {BizcardLabelColor} from '../Bizcard/Bizcard';
import GalleryPage, {BadgeId, RoleId} from '../GalleryPage/GalleryPage';
import PageTitleScreen from '../PageTitleScreen/PageTitleScreen';

enum RevealState {
    hidden = "hidden",
    revealed = "revealed",
    unrevealed = "unrevealed"
}


interface Props {
    screenWidth:number
}

interface State {
    page3RevealState:RevealState,
    projectsRevealState:RevealState,
    
}


export default class Experience extends React.Component<Props,State>{


    poll:PollData = {
        id:"experience",
        options:[
            {id:"option1", label:"1–4 years"},
            {id:"option2", label:"5–9 years"},
            {id:"option3", label:"10–14 years"},
            {id:"option4", label:"15–19 years"},
            {id:"option5", label:"20–24 years"}
        ],

        results:[
            {id:"option2", label:"5–9 years", value:30, valueMax:30, unit:"%"},
            {id:"option3", label:"10–14 years", value:19, valueMax:30, unit:"%"},
            {id:"option1", label:"1–4 years", value:18, valueMax:30, unit:"%"},
            {id:"option4", label:"15–19 years", value:10, valueMax:30, unit:"%"},
            {id:"option5", label:"20–24 years", value:8, valueMax:30, unit:"%"},
            {id:"option6", label:"Other", value:15, valueMax:30, unit:"%"},
            
        ],
        
    }

    experiencePoll:Poll;
    page1Bg:any;
    page1Title:any;
    

    constructor(props:Props){
        super(props);
        this.state = {
            page3RevealState: RevealState.revealed,
            projectsRevealState: RevealState.revealed,
        }

        
    }

    
    
    componentDidMount(): void {
        
        this.page1Bg = document.querySelector("#experience .page1-bg")
        this.page1Title = document.querySelector("#experience .screen-title")
        
    }

    reveal = async () => {
        // await Tools.later(2)
        
    }

   
   
    /**
     * User clicked an option in the poll
     * @param id - result id 
     */
    //  handleItemClick = (id:string) => {
    //     this.experiencePoll.scrollToResults();

    //     this.setState({page3RevealState:RevealState.revealed});
    //     this.setState({projectsRevealState:RevealState.revealed});
    // }

    // handlePage3Intersect = async (element:any, ratio:number, direction:RatioDirection, rect:any) => {
    //     // console.log("Skills.handlePage3Intersect()")
    //     if(this.state.page3RevealState === RevealState.revealed){


    //         await Tools.later(1);
    //         this.mySkillsPoll.revealResults();
    //     }
    // }

    handleTitleClick = () => {
        Tools.scrollIntoView("experience-page3");
        // const el = document.getElementById("experience-page3");
        // if(el) el.scrollIntoView();
    }

  

    render(){

        let page3Class = "page page3";
        if(this.state.page3RevealState === RevealState.revealed) page3Class += " revealed";

        let projectsClass = "projects";
        if(this.state.projectsRevealState === RevealState.revealed) projectsClass += " revealed";

        return(
            <section id={NavRouteId.experience}>
                <ScreenAnchor routeId={NavRouteId.experience}/>

             
                
                <div id="experience-content" className="content">
                    
                    {/* PAGE TITLE SCREEN */}
                    <PageTitleScreen
                        id="experience"
                        label="Experience"
                        onClick={this.handleTitleClick}
                    />


                    <div id="experience-pages" className="pages">

                        {/* PAGES TITLE */}
                        <PagesTitle 
                            id="experience" 
                            label="Experience" 
                            // onReveal={this.handlePagesReveal}
                            // onUnreveal={this.handlePagesUnreveal}
                        />


                        {/* PAGE 2 */}
                        {/* <div id="experience-page-2" className="page page2">
                            <div className='bg-image-container'>
                                <div className='bg-image'><img src="/images/experience/bg.png" alt=''/></div>
                            </div>
                            <div className='content-container'>
                                <div className='main-column'>
                                    <div className='content main-column-content'>
                                        <Bizcard
                                            id="experience_bizcard1"
                                            bubbleLabel={(<span>A quick <em>experience</em> question...</span>)}
                                            labelColor={BizcardLabelColor.eggshell}
                                        />
                                      
                                        <Poll
                                            ref={(ref)=>{if(ref) this.experiencePoll = ref}}
                                            onItemClick={this.handleItemClick}
                                            data={this.poll}
                                            optionLabelColor={PollOptionLabelColor.light}
                                            resultLabelColor={PollResultLabelColor.light}
                                            // showResults={true}
                                            question={(<h1 className="small"><b>Question:</b> What is the most common experience level for a professional programmer?</h1>)}
                                            answer={(<h1 className="small">MOST COMMON <b>EXPERIENCE LEVEL</b> FOR PROFESSIONAL PROGRAMMERS<span className='faint'>*</span>:</h1>)}
                                            footnote={(<p className='footnote'>*Source: <a href="https://insights.stackoverflow.com/survey/2021#section-experience-years-coding-professionally">https://insights.stackoverflow.com/survey/2021#section-experience-years-coding-professionally</a></p>)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* PAGE 3 */}
                        
                        <div id="experience-page3" className={page3Class}>
                    
                            <div className='content-container'>
                                <div className='bg-image-container'>
                                    <div className='bg-image'><img src="/images/experience/bg.png" alt=''/></div>
                                </div>
                                <div className='main-column'>
                                    <div className='content main-column-content'>

                                    

                                            <Intersector 
                                                // onIntersect={this.handlePage3Intersect}
                                                inViewAt={0.5}
                                                outOfViewAt={0.5}
                                                addClasses={true}
                                            >
                                            <div id="experience-page3-panels" className='panels'>
                                                <div className='panel panel1'>
                                                    <h1 className="small"><b>15 years experience</b></h1>
                                                    <div className='pic'>
                                                        <img src='/images/experience/panel1.png' alt=''/>
                                                    </div>
                                                    <p>
                                                        I've been working as front-end dev and UX designer for about 15 years.
                                                    </p>
                                                </div>

                                                <div className='panel panel2'>
                                                    <h1 className="small">
                                                        <b>INDUSTRY AWARDS</b>
                                                    </h1>
                                                    <div className='pic'>
                                                        <img src='/images/experience/panel2.png' alt=''/>
                                                    </div>
                                                    <p>
                                                        I've worked on some great projects that have been nominated for top industry awards.
                                                    </p>
                                                </div>

                                                <div className='panel panel3'>
                                                    <h1 className="small">
                                                        <b>Big Names</b>
                                                    </h1>
                                                    <div className='pic'>
                                                        <img src='/images/experience/panel3.png' alt=''/>
                                                    </div>
                                                    <p>
                                                        Over the years I've worked with some big names as well as smaller independents.
                                                    </p>
                                                </div>
                                            </div>
                                            </Intersector>
                                    
                                    </div>

                                    
                                </div>
                            </div>
                        </div>

                        <div className={projectsClass}>
                            <div className='main-column'>
                                
                                <div className='projects-title main-column-content'>
                                    <h1 className="small">
                                        <b>PROJECTS</b>
                                    </h1>
                                </div>
                            </div>

                            {/* SMALL PRODUCERS */}
                            <GalleryPage
                                id="small_producers"
                                type="Project"
                                title="Small Producers Info Hub"
                                summary="Information resource for small food producers in Scotland"
                                roles={[RoleId.leadFrontendDev, RoleId.leadUxDesigner]}
                                description="Commissioned by the Scottish Government, this website aims to provide access to essential information for small food producers throughout the country. The user-centred design process ensured a simple, accessible, and effective final product for all stakeholders."
                                skills='User-centred design, UX design, user testing, Figma, Adobe CS, vector illustration, branding, Accessibility, WordPress, TypeScript, SCSS, Node, PHP'
                                badges={[
                                    
                                    {id:BadgeId.website, alt:"Website"},
                                    {id:BadgeId.scotgov, alt:"Scottish Government"},
                                ]}
                                images={[
                                    {scale:1, width:1640, height:704}, // Home hero (apple)
                                    {scale:1, width:1640, height:794}, // Home hero menu (honey)
                                    {scale:1, width:1640, height:4860}, // Home (eggs)
                                    {scale:1, width:1640, height:1386}, // About
                                    {scale:1, width:1640, height:3107}, // Beekeeping
                                    
                                ]}
                                screenWidth={this.props.screenWidth}
                            />

                            {/* READABLE */}
                            <GalleryPage
                                id="readable"
                                type="Project"
                                title="Readable"
                                summary="Fun, bitesize stories for English learners"
                                roles={[RoleId.leadFrontendDev, RoleId.leadUxDesigner]}
                                description='Readable is a popular language learning mobile app which enables everyone to learn English for free by reading for pleasure. I led the design and build of the React Native front-end, as well as the React JS landing page for the app, and tons of other marketing assets. The app has so far achieved an average of 4.9 stars on Google Play globally, with 900K+ installs, and launched on iOS in December 2023. Readable was a finalist in the ELTons, the preeminent international English language teaching awards.'
                                skills='React Native, TypeScript, SQLite, Firebase, Node.js, Express, Android, iOS, React JS, SCSS, Automated testing, Sketch, Adobe CS, UX design, UI design, branding, book cover design, Agile'
                                badges={[
                                    {id:BadgeId.elton, alt:"British Council ELTon award nominee"},
                                    {id:BadgeId.mobileApp, alt:"Mobile app"}
                                ]}
                                images={[
                                    {scale:1, width:1640, height:1520},
                                    {scale:1, width:1640, height:1520},
                                    {scale:0.5, width:690, height:690},
                                    {scale:0.3, width:690, height:690},
                                    {scale:0.1, width:690, height:690},
                                ]}
                                screenWidth={this.props.screenWidth}
                            />


                            {/* BOCAN */}
                            <GalleryPage
                                id="bocan"
                                type="Project"
                                title="Bòcan"
                                summary="Website for Scottish Gaelic language learners"
                                roles={[RoleId.leadFrontendDev, RoleId.leadUxDesigner]}
                                description='Took the lead in revitalising the UX and front-end build for this Gaelic language learning website.'
                                skills='React JS, HTML, SCSS, Javascript, TypeScript, Automated testing, SEO, Node.js, Express, Sketch, Adobe CS, UX design, UI design, branding, Lottie, Git'
                                badges={[
                                    // {id:BadgeId.elton, alt:"British Council ELTon award nominee"},
                                    {id:BadgeId.website, alt:"Website"}
                                ]}
                                images={[
                                    {scale:1, width:1640, height:2146},
                                    {scale:1, width:1640, height:1378},
                                    {scale:1, width:1640, height:2146},
                                    {scale:1, width:1640, height:1378},
                                    // {scale:0.5, width:690, height:690},
                                    // {scale:0.4, width:320, height:276}
                                ]}
                                screenWidth={this.props.screenWidth}
                            />

                            {/* WORDABLE */}
                            <GalleryPage
                                id="wordable"
                                type="Project"
                                title="Wordable"
                                summary="Mobile app that makes vocabulary learning easy, fun, and social"
                                roles={[RoleId.leadFrontendDev, RoleId.leadUxDesigner]}
                                description='Designed and built the front-end for this fun language learning mobile app that pits learner against learner in a competitive battle. Average 4.7 stars on Google Play, with over 100K installs on iOS & Android. Finalist in the 2018 ELTon Awards (Innovation in Learner Resources category).'
                                skills='HaXe, SQLite, Android, iOS, HTML, Javascript, CSS, Adobe CS, UX design, UI design, branding, Agile'
                                badges={[
                                    {id:BadgeId.elton, alt:"British Council ELTon award nominee"},
                                    {id:BadgeId.mobileApp, alt:"Mobile app"}
                                ]}
                                images={[
                                    {scale:1, width:1640, height:1520},
                                    {scale:1, width:3084, height:1520},
                                    {scale:1, width:1640, height:1520},
                                    {scale:1, width:1640, height:1520},
                                    {scale:1, width:1640, height:1520},
                                    {scale:1, width:1640, height:1520},
                                    {scale:1, width:1640, height:1520},
                                    {scale:1, width:2498, height:1038},
                                    {scale:1, width:2340, height:1038},
                                    
                                ]}
                                screenWidth={this.props.screenWidth}
                            />

                            {/* ARIKA */}
                            <GalleryPage
                                id="arika"
                                type="Project"
                                title="Arika"
                                summary="Website for leading arts organisation"
                                roles={[RoleId.leadFrontendDev, RoleId.leadUxDesigner]}
                                description='Successful web modernisation and expansion for leading arts organisation Arika, winners of the Turner Prize in 2020.'
                                skills='HTML, LESS, Drupal, PHP, UX design, User Personas, User Stories, User Testing, Adobe CS, UX design, UI design'
                                badges={[
                                    // {id:BadgeId.elton, alt:"British Council ELTon award nominee"},
                                    {id:BadgeId.website, alt:"Website"}
                                ]}
                                images={[
                                    {scale:1, width:1640, height:4450},
                                    // {scale:0.5, width:690, height:690},
                                    // {scale:0.4, width:320, height:276}
                                ]}
                                screenWidth={this.props.screenWidth}
                            />


                            {/* LINGOPOLIS */}
                            <GalleryPage
                                id="lingopolis"
                                type="Project"
                                title="Lingopolis"
                                summary="Innovative language learning web app"
                                roles={[RoleId.leadUxDesigner, RoleId.frontendDev ]}
                                description='Led the UX design and front-end development for web app that successfully gamified the language learning experience using a city-building game system with integrated vocabulary learning. Finalist in the 2016 ELTon Awards (Digital Innovation category).'
                                skills='AS3, HTML, CSS, Adobe CS, UX design, UI design, branding, 3D modelling, Blender, animation, sound design, Agile'
                                badges={[
                                    {id:BadgeId.elton, alt:"British Council ELTon award nominee"},
                                    {id:BadgeId.webApp, alt:"Web app"}
                                ]}
                                images={[
                                    {scale:1, width:1640, height:1162},
                                    {scale:1, width:1640, height:2036},
                                    {scale:1, width:1640, height:1080}
                                ]}
                                screenWidth={this.props.screenWidth}
                            />

                            
                            
                            {/* AMAZON */}
                            <GalleryPage
                                id="amazon"
                                type="Project"
                                title="Amazon VOD"
                                summary="Rapid Prototyping for new Amazon VOD UI"
                                roles={[RoleId.frontendDev]}
                                description="Was a front-end UI developer for the prototyping phase of Amazon's new video-on-demand interface for their Fire TV platform."
                                skills='Javascript, Adobe CS, Agile, User testing'
                                badges={[
                                    {id:BadgeId.prototype, alt:"Prototype"},
                                    {id:BadgeId.amazon, alt:"Amazon"}
                                ]}
                                images={[
                                    {scale:1, width:1640, height:882},
                                    
                                ]}
                                screenWidth={this.props.screenWidth}
                            />

                            

                            {/* THINGS TO DO */}
                            <GalleryPage
                                id="things_to_do"
                                type="Project"
                                title="BBC Things To Do"
                                summary="UX Design for nationwide events service"
                                roles={[RoleId.leadUxDesigner]}
                                description='Led the UXD team for this nationwide service providing a one-stop-shop for public events.'
                                skills='User centered design, User Personas, User stories, User testing, Adobe CS, Agile'
                                badges={[
                                    // {id:BadgeId.bafta, alt:"BAFTA interactive learning award nominee"},
                                    // {id:BadgeId.bima, alt:"BIMA interactive media award winner"},
                                    {id:BadgeId.website, alt:"Website"},
                                    {id:BadgeId.bbc, alt:"BBC"}
                                ]}
                                images={[
                                    {scale:1, width:1640, height:1794},
                                    {scale:1, width:1640, height:1008},
                                    
                                ]}
                                screenWidth={this.props.screenWidth}
                            />

                            {/* NMS */}
                            <GalleryPage
                                id="nms"
                                type="Project"
                                title="NMS history explorer"
                                summary="Fun learning interactives exploring ancient history"
                                roles={[RoleId.leadUxDesigner, RoleId.frontendDev, RoleId.illustrator ]}
                                description='I was lead UX designer, illustrator, and co-front-end developer on this series of interactives for National Museums Scotland. The aim was to help kids explore the lives of ancient Egyptians and Romans in a fun and engaging way.'
                                skills='AS3, Adobe CS, UX design, UI design, illustration, animation, sound design'
                                badges={[
                                    // {id:BadgeId.bafta, alt:"BAFTA interactive learning award nominee"},
                                    // {id:BadgeId.bima, alt:"BIMA interactive media award winner"},
                                    {id:BadgeId.webApp, alt:"Web app"},
                                    {id:BadgeId.nms, alt:"National Museums Scotland"}
                                ]}
                                images={[
                                    {scale:1, width:1640, height:906},
                                    {scale:1, width:1640, height:906},
                                    {scale:1, width:1640, height:906},
                                    {scale:1, width:1640, height:906},
                                    {scale:1, width:1640, height:906},
                                    {scale:1, width:1640, height:906},
                                    
                                ]}
                                screenWidth={this.props.screenWidth}
                            />

                            {/* WEBWISE */}
                            <GalleryPage
                                id="webwise"
                                type="Project"
                                title="BBC Webwise"
                                summary="Web game development for award winning BBC project"
                                roles={[RoleId.leadUxDesigner, RoleId.frontendDev, RoleId.illustrator, RoleId.animator ]}
                                description='I was lead UX designer, illustrator, and co-front-end developer for the isometric-3D web game "Cobweb Castle" that formed an integral part of this BAFTA nominated online learning portal. The interactive gained the BBC’s highest Net Promoter score on bbc.co.uk for 18 consecutive months!'
                                skills='AS3, Adobe CS, UX design, UI design, illustration, pixel art, animation, sound design, Audacity'
                                badges={[
                                    {id:BadgeId.bafta, alt:"BAFTA interactive learning award nominee"},
                                    {id:BadgeId.bima, alt:"BIMA interactive media award winner"},
                                    // {id:BadgeId.website, alt:"Website"},
                                    {id:BadgeId.bbc, alt:"BBC"}
                                ]}
                                images={[
                                    {scale:1, width:1640, height:906},
                                    {scale:1, width:1640, height:906},
                                    {scale:1, width:1640, height:906},
                                    {scale:1, width:1640, height:906},
                                    {scale:1, width:1640, height:906},
                                    
                                ]}
                                screenWidth={this.props.screenWidth}
                            />

                            
                        </div>


                        
                    
                    </div>
                    
                </div>

            </section>
        )
    }
}
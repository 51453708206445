import EventEmitter from 'eventemitter3'
import { NavRouteId } from '../components/Nav/Nav';


export type NavChangeEvent = {
    routeId:NavRouteId,
    nextAppState:string,
    intoForeground:boolean,
    intoBackground:boolean,
}


export default class Radio extends EventEmitter {

	
	
    static NAV_CHANGE:string = "navChange";




}
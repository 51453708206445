import React from 'react';
import './_styles.scss';

import App from '../../App';


export default class Contact extends React.Component{

    handleThanksClick = () => {
        App.showModal("thanks");
    }

    render(){

        
        return(
            <footer>
                <div className="main-column">
                    <div className="main-column-content content">
                        <p>Made with <span className="heart">&#9829;</span> in 2024 &copy; Darri Donnelly. Thanks to <button onClick={this.handleThanksClick}>these lovely people</button></p>
                    </div>
                </div>
            </footer>
            
        )
    }
}
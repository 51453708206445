import React from 'react';

import './_styles.scss';
import '../../../node_modules/viewerjs/dist/viewer.min.css';

import Intersector, {RatioDirection} from '../Intersector/Intersector';
import Tools from '../../tools/Tools';
// import Viewer from 'viewerjs';


export type GalleryImageData = {
    // filename:string,o
    width:number,
    height:number,
    scale?:number,
    
}
const log = (...args:any) => { if(isLoggingOn) console.log(...args)}
const isLoggingOn = false;

enum LoadState {
    init="init",
    loading="loading",
    loaded="loaded",
    error="error"

}

interface Props {
    galleryId:string,
    imageData:GalleryImageData,
    index:number,
    galleryWidth:number,
    onClick:any,
}

interface State {
    isMounted:boolean,
    isInView:boolean,
    loadState:LoadState,
    isRevealed:boolean
}
export default class GalleryImage extends React.Component<Props, State>{

    colorClass:string = "col" + Math.floor((Math.random() * 4));

    constructor(props:Props){
        super(props)
        this.state = {
            isMounted: false,
            isInView: false,
            loadState: LoadState.init,
            isRevealed:false
        }
    }

    componentDidMount(): void {
        this.setState({
            isMounted: true
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        
        const shouldReveal:boolean = (
            !this.state.isRevealed && 
            (prevState.loadState === LoadState.loading && this.state.loadState === LoadState.loaded)
        );

        if(shouldReveal){
            this.reveal(0.1);
        }
    }

    get elId():string{
        return this.props.galleryId + "_image_" + this.props.index;
    }
    get imageElId():string{
        return this.props.galleryId + "_image_img_" + this.props.index;
    }

    get isLoaded():boolean {
        return this.state.loadState === LoadState.loaded;
    }
    
    isRevealing:boolean;
    reveal = async (delaySecs:number) => {
        if(this.isRevealing) return;
        this.isRevealing = true;
        await Tools.later(delaySecs)
        this.setState({isRevealed:true})
    }
    handleIntersect = (element:any, ratio:number, direction:RatioDirection, rect:any) => {
        if(ratio>0){
            if(!this.state.isInView){
                this.setState({
                    isInView: true,
                    loadState: LoadState.loading
                })
            }
        }
    }

    handleLoad = () => {

        this.setState({loadState:LoadState.loaded})
    }

    handleLoadError = () => {
        this.setState({loadState:LoadState.error})
    }
    

    handleClick = async (evt:any) => {
        const el = evt.currentTarget;
        this.props.onClick(this.imageElId);
    
    }

    render(): React.ReactNode {
        // console.log("GalleryImage.render()")
        if(!this.state.isMounted){
            return null
        }
        
        const galleryId = this.props.galleryId;
        const data = this.props.imageData;
        const index = this.props.index;
        const scale = data.scale || 1;
        const aspect = data.height / data.width;
        const galleryWidth = this.props.galleryWidth;
        const width = Math.ceil((galleryWidth * scale));
        const height = Math.ceil(aspect * width);
        let containerClass = "image " + this.colorClass;
        if(this.state.isRevealed) containerClass += " revealed";
        else containerClass += " unrevealed";
        
        
        return(
            
                <Intersector
                    
                    onIntersect={this.handleIntersect}
                    inViewAt={0.1}
                    outOfViewAt={0.5}
                >
                    
                    <div 
                        className={containerClass}
                        id={this.elId}
                        key={this.elId} 
                        style={{
                            width: width + "px",
                            height: height + "px",
                        }}
                    >
                        <div className='bg'/>
                        {this.state.isInView && 
                            <img 
                                src={"/images/experience/gallery/" + galleryId + "/" + index + ".png"}
                                alt=""
                                onLoad={this.handleLoad}
                                onError={this.handleLoadError}
                                onClick={this.handleClick}
                                id={this.imageElId}
                            />
                        }
                    </div>
                </Intersector>
         
        )
    }
}
import React from 'react';
import './_styles.scss';


enum LoadState{
    loading = "loading",
    loaded = "loaded",
    error = "error"
}


interface Props{

}

interface State {
    imageLoadState:LoadState
}



export default class ErrorScreen extends React.Component<Props, State>{
    
    constructor(props:Props){
        super(props);
        this.state = {
            imageLoadState: LoadState.loading
        }
    }
    handleImageLoad = () => {
        this.setState({imageLoadState:LoadState.loaded})
    }
    render(): React.ReactNode {

        let containerClass = 'screen-error';
        if(this.state.imageLoadState === LoadState.loading) containerClass += " loading";
        return (
            <div id="error" className={containerClass}>
                <div className='main-colunm'>
                    <div className='main-colunm-content'>
                        <div className="pic-wrapper">
                            <div className="pic">
                                <img 
                                    onLoad={this.handleImageLoad}
                                    src="/images/error/cat.png" 
                                    alt='Cute kitten'
                                />
                            </div>
                        </div>
                        <h1>404</h1>
                        <h1 className='small'><b>Page not found</b></h1>
                        <p>Please enjoy this picture of a kitten instead</p>
                    </div>
                    
                </div>
            </div>
        )
    }
}
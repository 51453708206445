import React from 'react';

import App from '../../App';
import Intersector, {RatioDirection} from '../Intersector/Intersector';
import './_styles.scss';
import Tools from '../../tools/Tools';


interface Props {
    id:string,
    label:string,
    onReveal?:Function,
    onUnreveal?:Function,
}
export default class PagesTitle extends React.Component<Props>{
    
    pagesTitle:any;

    componentDidMount(): void {
        this.pagesTitle = document.querySelector("#" + this.props.id + " .pages-title");    
    }


    reveal(){
        // console.log("PagesTitle.reveal()")
        this.pagesTitle.classList.add("revealed");
        Tools.addClassToHTML('section-' + this.props.id + '-pages');
        this.props.onReveal?.();
    }

    unreveal(){
        // console.log("PagesTitle.unreveal()")
        this.pagesTitle.classList.remove("revealed");
        Tools.removeClassFromHTML('section-' + this.props.id + '-pages');

        this.props.onUnreveal?.();
    }

    handlePagesTopIntersect = (element:any, ratio:number, direction:RatioDirection, rect:any) => {
        // console.log("PagesTitle.handlePagesTopIntersect()")
        if(!this.pagesTitle) return;
        
        // Reveal if going off screen at the top
        if(rect.top < 0){
            if(App.isScrollingToScreen) return
            this.reveal()
        }
        // Hide in all other cases
        else{
            // console.log(" rect.top >= 0")
            this.unreveal()

        }
    
    }
    handlePagesBottomIntersect = (element:any, ratio:number, direction:RatioDirection, rect:any) => {
        // console.log("PagesTitle.handlePagesBottomIntersect()")
        if(!this.pagesTitle) return;
 
        // Hide if going off screen at the top
        if(ratio === 0 && rect.top < 0){
            this.unreveal()
        }

        // Reveal if coming down into screen from the top
        else if(ratio > 0){
            // console.log(" - ratio > 0")
            if(App.isScrollingToScreen){
                // console.log(" - scrolling, do nothing")
                return
            }
            // console.log(" - reveal now")
            this.reveal()
        }
    }

    handlePagesIntersect = (element:any, ratio:number, direction:RatioDirection, rect:any) => {
        if(ratio === 0) this.pagesTitle.classList.remove("revealed");
    }

    render(): React.ReactNode {
            return(
                <div className="pages-title-container">
                    <Intersector onIntersect={this.handlePagesTopIntersect}><div id={this.props.id + "-pages-intersector-top"} className='pages-intersector top'/></Intersector>
                    <Intersector onIntersect={this.handlePagesBottomIntersect}><div id={this.props.id + "-pages-intersector-bottom"} className='pages-intersector bottom'/></Intersector>
                    <Intersector onIntersect={this.handlePagesIntersect}><div id={this.props.id + "-pages-intersector-all"} className='pages-intersector all'/></Intersector>
                    <div className='pages-title'>
                        <div className="slab"></div>
                        <div className="text">{this.props.label}</div>
                    </div>      
                </div>
            );
    }
}
import React from 'react';
import ModalThanks from '../ModalThanks/ModalThanks';
import './_styles.scss';
import Tools from '../../tools/Tools';

interface Props{
    modalId:string,
    onClose:Function,
}

interface State {
    isRevealed:boolean
}
export default class Modals extends React.Component<Props, State>{
    constructor(props:Props){
        super(props)
        this.state = {
            isRevealed: false
        }
    }

    // -----------------------------------------
    // LIFECYCLE

    componentDidMount(): void {
        document.addEventListener('keyup', this.handleKeyUp);
    }

    componentWillUnmount(): void {
        document.removeEventListener('keyup', this.handleKeyUp);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        const shouldReveal = 
            this.props.modalId
            && prevProps.modalId !== this.props.modalId;
        
        if(shouldReveal){
            this.reveal();
        }
    
    }

    // -----------------------------------------
    // METHODS

    getModalContent(){
        if(this.props.modalId === "thanks"){
            return <ModalThanks/>
        }

        return null
    }

    close(){
        
    }

    async reveal(delaySecs:number = 0){
        if(delaySecs) await Tools.later(delaySecs);
        this.setState({isRevealed:true})

    }

    async unreveal(delaySecs:number = 0){
        if(delaySecs) await Tools.later(delaySecs);
        this.setState({isRevealed:false})

        await Tools.later(0.3);

        this.props.onClose();

    }


    // -----------------------------------------
    // HANDLERS
    
    handleCloseClick = () => {
        this.unreveal();
    }

    handleScrimClick = () => {
        this.unreveal();
    }

    handleKeyUp = (e:any)=>{
        if(e.key === 'Escape'){
            this.unreveal();
        }
    }


    // -----------------------------------------
    // RENDER
    
    render(){

        if(!this.props.modalId) return null;
        
        let containerClass = "modal";
        if(this.state.isRevealed) containerClass += " revealed";

        return(
            <div className={containerClass}>
                {this.props.modalId &&
                    <React.Fragment>

                        <div className="modal-scrim" onClick={this.handleScrimClick}/>
                        
                        <div className="modal-content">
                            <button className="close-but" onClick={this.handleCloseClick} title="close">
                                <img src='/images/common/icon-close.png' alt=''/>
                            </button>
                            {this.getModalContent()}
                        </div>
                    </React.Fragment>
    }
            </div>
        );
    }
}
import React from 'react';
import './_styles.scss';
// import Tools from '../../tools/Tools';
// import BaseButton from '../BaseButton/BaseButton';
import App from '../../App';
import { NavRouteId } from '../Nav/Nav';
import Intersector, {InitPosition, InitView, RatioDirection} from '../Intersector/Intersector';
import Poll, {PollData} from '../Poll/Poll';
import ScreenAnchor from '../ScreenAnchor/ScreenAnchor';
import HomeBg from '../HomeBg/HomeBg';


enum RevealState {
    hidden = "hidden",
    revealed = "revealed",
    unrevealed = "unrevealed"
}


interface Props {

}

interface State {
    revealState:RevealState
}


export default class Home extends React.Component<Props,State>{

    poll:PollData = {
        id:"home",
        options:[
            {id:"skills", label:"Skills"},
            {id:"experience", label:"Experience"},
        ],
        
    }
    

    constructor(props:Props){
        super(props);
        this.state = {
            revealState: RevealState.hidden
        }

    }
    componentDidMount(){
        this.reveal();
    }

    reveal = async () => {
        // await Tools.later(2)
        this.setState({revealState: RevealState.revealed})
    }

    handleItemClick = (id:string) => {
        if(id==="skills"){
            App.routeToScreen(NavRouteId.skills)
        }else if(id==="experience"){
            App.routeToScreen(NavRouteId.experience)
        }
    }


    handleAnchorIntersect= (element:any, ratio:number, direction:RatioDirection, rect:any) => {
        if(ratio > 0) App.routeToScreen(NavRouteId.home, false);
    }

    render(){

        const containerClass = this.state.revealState;
        return(
            <section id={NavRouteId.home} className={containerClass}>

                <ScreenAnchor routeId={NavRouteId.home}/>
                
                <HomeBg/>
                
                <div className="main-column">
                    <div className="oval"/>
                    <div className="content main-column-content">
                        
                        <h1 className="small"><b>QUESTION:</b> What makes a great...</h1>   
                        <h1>Front-end dev?</h1>

                        
                        
                        <Poll
                            onItemClick={this.handleItemClick}
                            data={this.poll}
                            showResults={false}
                            revealOptionsAfterDelay={1}
                        />
                    </div>    
                </div>
                
                
            </section>
        )
    }
}
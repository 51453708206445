import React from 'react';


import Tools from '../../tools/Tools';

import './_styles_home_bg_icon.scss';
import {Pos} from './HomeBg';

enum LoadState{
    loading = "loading",
    loaded = "loaded",
    error = "error",
}


interface Props{
    id:string,
    imageId:string,
    pos:Pos,
    onPingComplete:Function,
}

interface State{
    isRevealed:boolean;
    loadState:LoadState;
}

export default class HomeBgIcon extends React.Component<Props, State>{
    
    revealDurationSecs = 1;
    durationMinSecs = 2;
    durationMaxSecs = 5;
    unrevealDurationSecs = 1;

    constructor(props:Props){
        super(props);
        this.state = {
            isRevealed: false,
            loadState: LoadState.loading,
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        
        const isJustLoaded = (prevState.loadState === LoadState.loading && this.state.loadState === LoadState.loaded);
        if(isJustLoaded) this.start();
    }


    get isLoaded():boolean{
        return (this.state.loadState === LoadState.loaded);
    }

    get imageElId():string{
        return "home-bg-icon-image" + this.props.id;
    }

    
    start(){
        this.ping();
    }

    ping = async (loop:boolean = true) => {
        
        const revealDelaySecs = Math.random() * 3;
        const durationSecs = this.durationMinSecs + (Math.random() * (this.durationMaxSecs - this.durationMinSecs));
        await this.reveal(revealDelaySecs)
        await this.unreveal(durationSecs);

        this.props.onPingComplete(this.props.id);

        if(loop){
            await Tools.later(1);
            this.ping();
        }
    }

   

    reveal = async (delaySecs:number = 0) => {
        if(delaySecs !== 0) await Tools.later(delaySecs);
        this.setState({isRevealed:true})
        await Tools.later(this.revealDurationSecs);
    }

    unreveal = async (delaySecs:number = 0) => {
        if(delaySecs !== 0) await Tools.later(delaySecs);
        this.setState({isRevealed:false})
        await Tools.later(this.unrevealDurationSecs);
    }

    handleLoad = () => {
        this.setState({loadState:LoadState.loaded})
    }

    handleError = () => {
        this.setState({loadState:LoadState.error})
    }

    render(): React.ReactNode {
        let containerClass = "home-bg-icon";
        if(this.state.isRevealed) containerClass += " revealed"
        return(
            <div 
                className={containerClass} 
                style={
                    {
                        left: this.props.pos.x, 
                        top: this.props.pos.y
                    }
                    // {
                    // transform: `translateX: ${this.state.pos.x} translateY: ${this.state.pos.y }`,
                    // }
                }
            >
                <img 
                    src={'/images/common/home-' + this.props.imageId + '.png'} 
                    alt=''
                    onError={this.handleError}
                    onLoad={this.handleLoad}
                    id={this.imageElId}
                />
            </div>
        )
    }
}
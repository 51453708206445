import React from 'react';

import './_styles.scss'


import Tools from '../../tools/Tools';
import Screen from '../../tools/Screen';
import Intersector, {InitView} from '../Intersector/Intersector';

const log = (...args:any) => { if(isLoggingOn) console.log(...args)}
const isLoggingOn = false;

export interface PollData{
    id:string,
    options?:PollOption[],
    results?:PollResult[]
}
export interface PollOption{
    id:string,
    label:string,
}

export interface PollResult{
    id:string,
    label:string,
    value:number,
    valueMax?:number,
    unit?:string,
    
}


export enum PollOptionLabelColor{
    light = "light",
    dark = "dark"
}

export enum PollResultLabelColor{
    light = "light",
    dark = "dark"
}

export enum PollResultLabelSize{
    small = "small",
    extraSmall = "extra-small",
}

export enum PollResultBarSize{
    small = "small",
    extraSmall = "extra-small",
}


const colorBetween = require('color-between');

interface Props {
    data:PollData,
    onItemClick?:any,
    question?:any,
    answer?:any,
    footnote?:any,
    showResults?:boolean,
    optionLabelColor?:PollOptionLabelColor,
    resultLabelColor?:PollResultLabelColor,
    resultLabelSize?:PollResultLabelSize,
    resultBarSize?:PollResultBarSize,
    revealManually?:boolean,
    revealOptionsAfterDelay?:number,
    
}

interface State {
    showResults:boolean
}



export default class Poll extends React.Component<Props, State> {

    resultStartColor = "#71EFF1"
    resultEndColor = "#DDF88A"

    constructor(props:Props){
        super(props);
        this.state = {
            showResults: (this.props.showResults === true)
        }

        
    }

    componentDidMount(): void {
        // console.log("Poll.componentDidMount()")
        if(this.state.showResults && !this.props.revealManually){
            this.revealResults();
        }

        if(this.props.revealOptionsAfterDelay)this.revealOptions(this.props.revealOptionsAfterDelay);
            
    }

    revealOptions = async (delaySecs:number = 0) => {
        // log("Poll.revealOptions()")
        if(delaySecs) await Tools.later(delaySecs);
        let index = 0;
        const revealDurationSecs = 0.4;
        const numOptions = this.props.data.options.length;
        for(let option of this.props.data.options){
            const delaySecs = index * (revealDurationSecs / numOptions);
            this.revealOption(option, delaySecs)
            index ++;
        }
    
    }

    async revealOption (option:PollOption, delaySecs:number = 0){
        const id = this.getOptionId(option.id);
        const el = document.getElementById(id);
        if(delaySecs) await Tools.later(delaySecs);
        el.classList.remove("out-of-view");
        el.classList.add("in-view");
    }

    
    revealResults = async () => {
        // var el = document.getElementById(this.resultsId);
        // if(el) el.scrollIntoView();

        await Tools.later(0.5);

        const totResults = this.props.data.results.length - 1;
        const resultDelay = 0.5/totResults;
        
        let i = 0;
        for(let result of this.props.data.results){
            const labelElId = this.getResultLabelId(result.id);
            const labelEl:any = document.getElementById(labelElId);
            if(labelEl){
                labelEl.classList.add("revealed")
            }


            const barElId = this.getResultBarId(result.id);
            const barEl:any = document.getElementById(barElId);
            const valueMax:number = result.valueMax || 100;
            if(barEl){
                const fraction = result.value / valueMax;
                
                // Width
                const maxPercent = Screen.isScreenBreakpointSmallDown() ? 75 : 90;
                barEl.style.width = (fraction * maxPercent) + "%";

                // Color
                let col = colorBetween(this.resultStartColor, this.resultEndColor, (i/totResults), 'hex');
                barEl.style.backgroundColor = col;

                // Opacity etc
                barEl.classList.add("revealed")
               
            }

            await Tools.later(resultDelay);
            i++;
        }
    }
    handleOptionClick = (id:string) => {
        if(this.props.showResults === false){
            if(this.props.onItemClick) this.props.onItemClick(id)
            return;
        }

        this.setState({showResults: true}, ()=>{
            this.revealResults();
            if(this.props.onItemClick) this.props.onItemClick(id)
        });
        
    }

    scrollToResults(){
        var el = document.getElementById(this.resultsId);
        if(el) el.scrollIntoView();
    }

    get resultsId():string{
        return this.props.data.id + "_results";
    }
    
    getOptionId(id:string):string{
        return this.props.data.id + "_option_" + id ;
    }

    getResultId(id:string):string{
        return this.props.data.id + "_result_" + id ;
    }
    
    getResultBarId(id:string):string{
        return this.props.data.id + "_result_bar_" + id ;
    }
    getResultLabelId(id:string):string{
        return this.props.data.id + "_result_label_" + id ;
    }
    
    render(){

        let questionClass = "question";
        if(this.props.data.results) questionClass += " with-results-spacer"

        let butClass = "";
        if(this.props.optionLabelColor) butClass += this.props.optionLabelColor;

        let resultLabelClass = "label";
        if(this.props.resultLabelSize) resultLabelClass += " " + this.props.resultLabelSize;
        if(this.props.resultLabelColor) resultLabelClass += " " + this.props.resultLabelColor;

        let resultValueClass = "value";
        if(this.props.resultLabelColor) resultValueClass += " " + this.props.resultLabelColor;

        let resultBarClass = "bar";
        if(this.props.resultBarSize) resultBarClass += " " + this.props.resultBarSize;

        return(
            <div className="poll">


                {/* QUESTION */}
                {this.props.data.options &&
                    <div className={questionClass}>
                        {this.props.question && this.props.question}
                        <ul>
                            {this.props.data.options.map((option:PollOption) => {
                                const id = this.getOptionId(option.id);
                                return(
                                    <Intersector
                                        addClasses={true}
                                        initView={InitView.out}
                                        key={id + "_intersector"}
                                        inViewAt={0.01}
                                        outOfViewAt={0.99}
                                    >
                                    <li id={id}>
                                        <button 
                                            onClick={(e)=>{this.handleOptionClick(option.id)}}  
                                            className={butClass}
                                        >
                                            {option.label}
                                        </button>
                                    </li>
                                    </Intersector>
                                )
                            })}
                            
                        </ul>
                    </div>
                }
                
                {/* RESULTS */}
                {this.state.showResults &&
                    <div className='results' >
                        <div id={this.resultsId} className="anchor"/>
                        {this.props.answer && this.props.answer}
                        
                        
                        <table>
                            <tbody>
                            {this.props.data.results.map((result:PollResult) => {
                                const labelId = this.getResultLabelId(result.id);
                                const barId = this.getResultBarId(result.id);

                                if(result.id==="_group"){
                                    return(
                                        <tr
                                        key={barId}    
                                    >
                                        <td className="label-cell group" colSpan={2}>
                                            <div className={resultLabelClass} id={labelId}><b>{result.label}</b></div>
                                        </td>
                                    </tr>
                                    )
                                }
                                return(
                                    <tr
                                        key={barId}    
                                    >
                                        <td className="label-cell">
                                            <div className={resultLabelClass} id={labelId}>{result.label}</div>
                                        </td>
                                        <td className="bar-cell">
                                            <div className='bar-and-value-container'>    
                                                <div className={resultBarClass} id={barId}/>
                                                {result.unit && 
                                                    <div className={resultValueClass}>{result.value + result.unit}</div>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                            
                        </table>

                        {this.props.footnote && this.props.footnote}
                    </div>

                    
                }
                
            </div>
        )

    }

}